require('../css/theme.scss');

import $ from "jquery";
global.$ = global.jQuery = $;

import 'bootstrap';

// Markdown Editor //
var markdown = require('markdown/lib/markdown.js');
global.markdown = markdown;
require('to-markdown/dist/to-markdown.js');
require('bootstrap-markdown/js/bootstrap-markdown.js');

// datetime picker //
require('eonasdan-bootstrap-datetimepicker');